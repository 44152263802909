import imageSizes from '../../constants/imageSizes'

const heroImageSizes = imageSizes.columns5_34

const PageProductEnhancementAuthentication = {
  contentType: 'PageProductEnhancementAuthentication',
  fields: [
    {
      id: 'hero',
      name: 'Hero',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: heroImageSizes }
    },
    {
      id: 'benefits',
      name: 'Benefits',
      type: 'Array',
      subType: 'ModuleUsp'
    },
    {
      id: 'contentBlock1',
      name: 'Content block 1',
      type: 'BlockGroup',
      imageOptions: { resize: true, sizes: imageSizes.columns6_11, lazy: true }
    },
    {
      id: 'contentBlock2',
      name: 'Content block 2',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: imageSizes.columns6_11, lazy: true }
    },
    {
      id: 'quote',
      name: 'Quote',
      type: 'BlockQuote'
    },
    {
      id: 'contentBlock3',
      name: 'Content block 3',
      type: 'BlockContent',
      imageOptions: {
        resize: true,
        sizes: {
          small: {
            width: 327,
            height: 245,
            aspectRatio: '4/3'
          },
          medium: {
            width: 312,
            height: 416,
            aspectRatio: '3/4'
          },
          large: {
            width: 576,
            height: 468,
            aspectRatio: '4/3'
          }
        },
        lazy: true
      }
    },
    {
      id: 'contentBlock4',
      name: 'Content block 4',
      type: 'BlockGroup',
      subType: 'ModuleUsp'
    },
    {
      id: 'notificationBar',
      name: 'Notification Bar',
      type: 'BlockNotificationBar'
    }
  ]
}

const PageProductEnhancementRevenueAccelerate = {
  contentType: 'PageProductEnhancementRevenueAccelerate',
  fields: [
    {
      id: 'hero',
      name: 'Hero',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: heroImageSizes }
    },
    {
      id: 'benefits',
      name: 'Benefits',
      type: 'Array',
      subType: 'ModuleUsp'
    },
    {
      id: 'video',
      name: 'Video',
      type: ['ModuleVideo', 'ModuleInternalVideo'],
      imageOptions: { resize: true, sizes: imageSizes.columns12_5269, lazy: true }
    },
    {
      id: 'contentBlock1',
      name: 'Content block 1',
      type: 'BlockGroup',
      subType: 'BlockContent',
      imageOptions: { resize: true, sizes: imageSizes.columns6_11, lazy: true }
    },
    {
      id: 'contentBlock2',
      name: 'Content block 2',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: imageSizes.columns6_43, lazy: true }
    },
    {
      id: 'quote',
      name: 'Quote',
      type: 'BlockQuote'
    },
    {
      id: 'cta',
      name: 'Call to action',
      type: 'BlockCallToAction'
    },
    {
      id: 'notificationBar',
      name: 'Notification Bar',
      type: 'BlockNotificationBar'
    }
  ]
}

const PageProductEnhancementRevenueProtect = {
  contentType: 'PageProductEnhancementRevenueProtect',
  fields: [
    {
      id: 'hero',
      name: 'Hero',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: heroImageSizes }
    },
    {
      id: 'benefits',
      name: 'Benefits',
      type: 'Array',
      subType: 'ModuleUsp'
    },
    {
      id: 'video',
      name: 'Video',
      type: ['ModuleVideo', 'ModuleInternalVideo'],
      imageOptions: { resize: true, sizes: imageSizes.columns12_5269, lazy: true }
    },
    {
      id: 'logoBar',
      name: 'Logo bar',
      type: 'BlockLogoBar'
    },
    {
      id: 'contentBlock1',
      name: 'Content block 1',
      type: 'BlockGroup',
      subType: 'BlockContent',
      imageOptions: { resize: true, sizes: imageSizes.columns6_43, lazy: true }
    },
    {
      id: 'tableTitle',
      name: 'Table title',
      type: 'Symbol'

    },
    {
      id: 'tableDescription',
      name: 'Table description',
      type: 'RichText'
    },
    {
      id: 'table',
      name: 'Table',
      type: 'RichText'
    },
    {
      id: 'tableLink',
      name: 'Table link',
      type: 'ModuleNavigationLink'
    },
    {
      id: 'contentBlock2',
      name: 'Content block 2',
      type: 'BlockGroup',
      subType: 'ModuleUsp'
    },
    {
      id: 'cta',
      name: 'Call to action',
      type: 'BlockCallToAction'
    },
    {
      id: 'notificationBar',
      name: 'Notification Bar',
      type: 'BlockNotificationBar'
    }
  ]
}

const PageProductEnhancementCustomerArea = {
  contentType: 'PageProductEnhancementCustomerArea',
  fields: [
    {
      id: 'hero',
      name: 'Hero',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: heroImageSizes }
    },
    {
      id: 'benefits',
      name: 'Benefits',
      type: 'Array',
      subType: 'ModuleUsp'
    },
    {
      id: 'contentBlock1',
      name: 'Content block 1',
      type: 'BlockGroup',
      subType: 'BlockContent',
      imageOptions: { resize: true, sizes: imageSizes.columns6_43, lazy: true }
    },
    {
      id: 'contentBlock2',
      name: 'Content block 2',
      type: 'BlockGroup',
      subType: 'BlockTabContentLazy',
      lazy: true,
      imageOptions: { resize: true, sizes: imageSizes.columns6_43, lazy: true }
    },
    {
      id: 'contentBlock3',
      name: 'Content block 3',
      type: 'BlockGroup',
      subType: 'ModuleUsp'
    },
    {
      id: 'contentBlock4',
      name: 'Content block 4',
      type: 'BlockGroup'
    },
    {
      id: 'contentBlock4Image',
      name: 'Content block 4 Image',
      type: 'ModuleMedia',
      imageOptions: { resize: true, sizes: imageSizes.columns12_169, lazy: true }
    },
    {
      id: 'contentBlock4LogoBar',
      name: 'Content block 4 logo bar',
      type: 'BlockLogoBar'
    },
    {
      id: 'contentBlock5',
      name: 'Content block 5',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: imageSizes.columns6_43, lazy: true }
    },
    {
      id: 'cta',
      name: 'Call to action',
      type: 'BlockCallToAction'
    },
    {
      id: 'notificationBar',
      name: 'Notification Bar',
      type: 'BlockNotificationBar'
    }
  ]
}

export default {
  PageProductEnhancementAuthentication,
  PageProductEnhancementRevenueAccelerate,
  PageProductEnhancementRevenueProtect,
  PageProductEnhancementCustomerArea
}
