import imageSizes from '../../constants/imageSizes'

const heroImageSizes = imageSizes.columns5_34
const heroVerticalImageSizes = imageSizes.columns12_169

const PageProductOnlinePayments = {
  contentType: 'PageProductOnlinePayments',
  fields: [
    {
      id: 'notificationBar',
      name: 'Notification Bar',
      type: 'BlockNotificationBar'
    },
    {
      id: 'hero',
      name: 'Hero',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: heroImageSizes }
    },
    {
      id: 'logoBar',
      name: 'Logo bar',
      type: 'BlockLogoBar'
    },
    {
      id: 'benefits',
      name: 'Benefits',
      type: 'Array',
      subType: 'ModuleUsp'
    },
    {
      id: 'image',
      name: 'Image',
      type: 'ModuleMedia',
      imageOptions: { resize: true, sizes: imageSizes.columns12_52 }
    },
    {
      id: 'contentBlock1',
      name: 'Content block 1',
      type: 'BlockGroup',
      subType: 'BlockContent'
    },
    {
      id: 'contentBlock2',
      name: 'Content block 2',
      type: 'BlockContent',
      imageOptions: {
        resize: true,
        sizes: {
          small: { width: 327, height: 70 },
          medium: { width: 672, height: 150 },
          large: { width: 1200, height: 158 }
        }
      }
    },
    {
      id: 'contentBlock3',
      name: 'Content block 3',
      type: 'BlockGroup',
      subType: 'BlockContent'
    },
    // NEW: Image
    {
      id: 'image2',
      name: 'Image',
      type: 'ModuleMedia',
      imageOptions: { resize: true, sizes: imageSizes.columns12_52 }
    },
    // NEW: Content Block 4 - Block - USP
    {
      id: 'contentBlockUsp',
      name: 'Content block USP',
      type: 'BlockUspList'
    },
    // RENAME: Content Block 5 -> rename block 4 to 5 (check if needed)
    {
      id: 'contentBlock4',
      name: 'Content block 4',
      type: 'BlockGroup',
      subType: 'BlockTabContentLazy',
      lazy: true,
      imageOptions: {
        resize: true,
        sizes: {
          small: { width: 336, height: 'auto', aspectRatio: '16/9' },
          medium: { width: 320, height: 'auto', aspectRatio: '16/9' },
          large: { width: 368, height: 'auto', aspectRatio: '16/9' }
        }
      }
    },
    // RENAME: Content Block 6 -> rename block 7 to 6 (check if needed)
    {
      id: 'contentBlock7',
      name: 'Content block 7',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: imageSizes.columns6_11, lazy: true }
    },
    // RENAME to CTA block ?
    {
      id: 'contentBlock8',
      name: 'Content block 8',
      type: 'BlockCallToAction'
    },
    // NEW: FAQ Block
    {
      id: 'faq',
      name: 'FAQ',
      type: 'BlockFaqGroup'
    }
  ]
}

const PageProductInApp = {
  contentType: 'PageProductInApp',
  fields: [
    {
      id: 'notificationBar',
      name: 'Notification Bar',
      type: 'BlockNotificationBar'
    },
    {
      id: 'hero',
      name: 'Hero',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: heroVerticalImageSizes }
    },
    {
      id: 'logoBar',
      name: 'Logo bar',
      type: 'BlockLogoBar'
    },
    {
      id: 'contentBlock1',
      name: 'Content block 1',
      type: 'BlockGroup',
      subType: 'BlockContent',
      imageOptions: { resize: true, sizes: imageSizes.columns6_11 }
    },
    {
      id: 'contentBlock2',
      name: 'Content block 2',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: imageSizes.columns12_5269 }
    },
    {
      id: 'usps',
      name: 'Usps',
      type: 'Array',
      subType: 'ModuleUsp'
    },
    {
      id: 'contentBlock3',
      name: 'Content block 3',
      type: 'BlockGroup',
      subType: 'BlockContent',
      imageOptions: { resize: true, sizes: imageSizes.columns6_43 }
    },
    {
      id: 'contentBlock4',
      name: 'Content block 4',
      type: 'BlockContent',
      imageOptions: {
        resize: true,
        // 5/2 size on all devices, we don't currently have it in the imageSizes as we don't have other use cases
        sizes: {
          small: { width: 327, height: 130, aspectRatio: '5/2' },
          medium: { width: 672, height: 268, aspectRatio: '5/2' },
          large: { width: 1200, height: 480, aspectRatio: '5/2' }
        }
      }
    },
    {
      id: 'cta',
      name: 'Call to action',
      type: 'BlockCallToAction'
    }
  ]
}

// TODO: fix the image sizes for contentBlock1
const PageProductUnifiedCommerce = {
  contentType: 'PageProductUnifiedCommerce',
  fields: [
    {
      id: 'notificationBar',
      name: 'Notification Bar',
      type: 'BlockNotificationBar'
    },
    {
      id: 'hero',
      name: 'Hero',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: heroImageSizes }
    },
    {
      id: 'benefits',
      name: 'Benefits',
      type: 'Array',
      subType: 'ModuleUsp'
    },
    {
      id: 'contentBlock1',
      name: 'Content block 1',
      type: 'BlockGroup',
      subType: 'BlockContent'
    },
    {
      id: 'logoBar1',
      name: 'Logo bar 1',
      type: 'BlockLogoBar'
    },
    {
      id: 'contentBlock2',
      name: 'Content block 2',
      type: 'BlockGroup',
      subType: 'BlockContent'
    },
    {
      id: 'callToAction1',
      name: 'Call to action 1',
      type: 'BlockCallToAction'
    },
    {
      id: 'contentBlock3',
      name: 'Content block 3',
      type: 'BlockMediaCardGroup',
      imageOptions: { resize: true, sizes: imageSizes.columns6_11 }
    },
    {
      id: 'callToAction2',
      name: 'Call to action 2',
      type: 'Array',
      subType: 'BlockCallToAction'
    },
    {
      id: 'contentBlock4',
      name: 'Content block 4',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: imageSizes.columns6_11 }
    },
    {
      id: 'quote',
      name: 'Quote',
      type: 'BlockQuote'
    },
    {
      id: 'contentBlock5',
      name: 'Content block 5',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: imageSizes.columns6_43 }
    },
    {
      id: 'logoBar2',
      name: 'Logo bar 2',
      type: 'BlockLogoBar'
    },
    {
      id: 'bottomPageCta',
      name: 'Bottom page CTA',
      type: 'BlockCallToAction'
    },
    {
      id: 'bottomPageDoubleCta',
      name: 'Bottom page double CTA',
      type: 'Array',
      subType: 'BlockCallToAction'
    }
  ]
}

const PageProductPlatformPayments = {
  contentType: 'PageProductPlatformPayments',
  fields: [
    {
      id: 'notificationBar',
      name: 'Notification Bar',
      type: 'BlockNotificationBar'
    },
    {
      id: 'hero',
      name: 'Hero',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: heroImageSizes }
    },
    {
      id: 'benefits',
      name: 'Benefits',
      type: 'Array',
      subType: 'ModuleUsp'
    },
    {
      id: 'contentBlock1',
      name: 'Content block 1',
      type: 'BlockContent',
      imageOptions: {
        resize: true,
        sizes: {
          small: {
            width: 327,
            height: 245,
            aspectRatio: '4/3'
          },
          medium: {
            width: 312,
            height: 234,
            aspectRatio: '4/3'
          },
          large: {
            width: 576,
            height: 432,
            aspectRatio: '4/3'
          }
        }
      }
    },
    {
      id: 'contentBlock2',
      name: 'Content block 2',
      type: 'BlockGroup',
      subType: 'BlockContent',
      imageOptions: { resize: true, sizes: imageSizes.columns6_11 }
    },
    {
      id: 'contentBlock3A',
      name: 'Content block 3 (Remain compliant)',
      type: 'BlockGroup',
      subType: 'ModuleUsp'
    },
    {
      id: 'quote',
      name: 'Quote',
      type: 'BlockQuote'
    },
    {
      id: 'image',
      name: 'Image',
      type: 'ModuleMedia',
      imageOptions: { resize: true, sizes: imageSizes.columns12_52 }
    },
    {
      id: 'quote',
      name: 'Quote',
      type: 'BlockQuote'
    },
    {
      id: 'contentBlock4',
      name: 'Content block 4',
      type: 'BlockGroup',
      subType: 'BlockContent'
    },
    {
      id: 'faq',
      name: 'FAQ',
      type: 'BlockFaqGroup'
    },
    {
      id: 'faq',
      name: 'FAQ',
      type: 'BlockFaqGroup'
    },
    {
      id: 'callToAction',
      name: 'Call to action',
      type: 'BlockCallToAction'
    },
    {
      id: 'logobar',
      name: 'Logo bar',
      type: 'BlockLogoBar'
    },
    {
      id: 'contentBlock6',
      name: 'Content block 6',
      type: 'BlockGroup',
      subType: 'ModuleUsp'
    },
    // @TODO missing fragment
    // {
    //   id: 'relatedContent',
    //   name: 'Related content',
    //   type: 'BlockRelatedContent',
    //   localized: true
    // }
  ]
}

const PageProductPayouts = {
  contentType: 'PageProductPayouts',
  fields: [
    {
      id: 'notificationBar',
      name: 'Notification Bar',
      type: 'BlockNotificationBar'
    },
    {
      id: 'hero',
      name: 'Hero',
      type: 'BlockContent',
      imageOptions: { resize: true, sizes: heroImageSizes }
    },
    {
      id: 'benefits',
      name: 'Benefits',
      type: 'BlockGroup',
      subType: 'ModuleUsp'
    },
    {
      id: 'contentBlock1',
      name: 'Content block 1',
      type: 'BlockGroup',
      subType: 'BlockContent'
    },
    {
      id: 'contentBlock2',
      name: 'Content block 2',
      type: 'BlockGroup',
      subType: 'ModuleUsp'
    },
    {
      id: 'media',
      name: 'Media',
      type: 'ModuleMedia',
      imageOptions: { resize: true, sizes: imageSizes.columns12_52 }
    },
    {
      id: 'contentBlock3',
      name: 'Content block 3',
      type: 'BlockGroup',
      subType: 'ModuleUsp'
    },
    {
      id: 'quote',
      name: 'Quote',
      type: 'BlockQuote'
    },
    {
      id: 'contentBlock4',
      name: 'Content block 4',
      type: 'BlockContent'
    },
    {
      id: 'contentBlock5',
      name: 'Content block 5',
      type: 'BlockGroup',
      subType: 'ModuleUsp'
    },
    {
      id: 'faqs',
      name: 'FAQ',
      type: 'BlockFaqGroup'
    },
    {
      id: 'cta',
      name: 'Call to action',
      type: 'BlockCallToAction'
    }
  ]
}

export default {
  PageProductOnlinePayments,
  PageProductInApp,
  PageProductUnifiedCommerce,
  PageProductPlatformPayments,
  PageProductPayouts,
  // TODO migrate
  // PageProductDynamicCurrencyConversion,
  // PageProductGlobalPaymentProcessing,
  // PageProductPayByLink,
  // PageProductPlugins,
  // PageProductPosPayments,
  // PageProductWebPayments,
  // PageProductAcceptPayments,
  // PageProductDataConnect
}
