import mapperIcon from '../../utils/mappers/mapper-icon'

/**
 * Module charities sdg mapper
 * @param {Object} data - content data
 * @return {Object} - normalized data
 */
export const mapperModuleCharitiesSdg = (data: any) => {
  if (!data) {
    return null
  }

  return {
    id: data.sys?.id || '',
    name: data.name || '',
    icon: mapperIcon(data.icon)
  }
}
