import { mapperModuleNavigationLink, mapperModuleMedia } from '../../modules/mappers'
import { type MapperOptions } from '@/types'
/**
 * BlockHighlight
 * @param {Object} data, content data
 * @param {Function} getUrl - returns the url
 * @return {Object} normalized data
 */
export default (data: any, options: MapperOptions) => {
  if (!data) {
    return null
  }
  return {
    eyebrow: data?.eyebrow || '',
    title: data?.title || '',
    text: data?.text || '',
    image: mapperModuleMedia(data.image, options.imageOptions),
    link: mapperModuleNavigationLink(data.link, options.getUrl)
  }
}
