import { gql } from 'graphql-tag'
import { fragmentModuleNavigationLink, fragmentModuleMedia } from '../../modules/fragments'

export default gql`
  ${fragmentModuleNavigationLink}
  ${fragmentModuleMedia}
  fragment BlockHighlightFragment on BlockHighlight {
    eyebrow
    title
    text
    image {
      ...ModuleMediaFragment
    }
    link {
      ...ModuleNavigationLinkFragment
    }
  }
`
