import ProductDefinitions from './products'
import ProductEnhancementsDefinitions from './products-enhancement'
import AboutDefinitions from './about'
import TerminalDefinitions from './terminals'
import IndustryDefinitions from './industry'
import FinancialDefinitions from './financial'
import PageHome from './home'
import KnowledgeHubDefinitions from './knowledgeHub'
import PartnersDefinitions from './partners'
import CharityDefinitions from './charity'
import CareersDefinitions from './careers'
import InvestorDefinitions from './investors'

export default {
  PageHome,
  ...AboutDefinitions,
  ...TerminalDefinitions,
  ...ProductDefinitions,
  ...ProductEnhancementsDefinitions,
  ...IndustryDefinitions,
  ...FinancialDefinitions,
  ...KnowledgeHubDefinitions,
  ...PartnersDefinitions,
  ...CharityDefinitions,
  ...CareersDefinitions,
  ...InvestorDefinitions
}
