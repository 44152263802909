import { gql } from 'graphql-tag'

export default gql`
    fragment ModuleCharitiesSdgFragment on ModuleCharitiesSdg {
        sys {
            id
        }
        name
        icon
    }
`
