import { gql } from 'graphql-tag'
/**
 * GlobalDataPaymentMethodType
 *
 * fetches global data payment method type collection for all locales in alphabetical order
 */
export default function ({ preview, locales }, queryAllLocales: Function) {
  return gql`
  fragment GlobalDataPaymentMethodTypeFragment on GlobalDataPaymentMethodType {
    sys {
      id
    }
    name
    icon
  }

  query globalDataPaymentMethodTypeCollectionQuery {
    ${queryAllLocales({
    typeName: 'globalDataPaymentMethodTypeCollection',
    fragment: 'GlobalDataPaymentMethodTypeFragment',
    limit: '20',
    order: 'name_ASC'
  }, { preview, locales })}
  }
`
}
