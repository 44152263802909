export const mapperModuleNavigationLink = (data: any, getUrl: Function, log?: boolean) => {
  if (!data || !Object.keys(data).length) {
    return null
  }

  const urlData = getUrl({ internalLink: data.internalLink, externalLink: data.externalLink }, log) || ''

  if (log) {
    /* eslint-disable no-console */
    console.info({ urlData })
  }

  const url = urlData?.url || ''
  const isNuxtLink = urlData?.route?.payload?.nuxtLink

  if (!url && log) {
    console.warn(`Link for "${data.title}" (${data.sys?.id}) could not be retrieved`)
  }

  return {
    id: data.sys?.id || '',
    title: data.title || data.text || '',
    // TODO We need to unify all the text, title and description
    text: data.text ? data.text : data.title,
    url,
    lazyUrlEndpoint: urlData?.lazyUrlEndpoint,
    contentType: data.internalLink?.contentType || '',
    nuxt: !!isNuxtLink,
    openInNewWindow: !!(data.openInNewWindow || data.externalLink)
  }
}
